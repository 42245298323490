import React from 'react';
import './skills-description.scss'
import BackgroundImage from 'gatsby-background-image'

const SkillsDescription = (props) => {
  const { content } = props;

  return (
    <div className="skill-block">
      <BackgroundImage Tag="div"
        className="skill-image"
        fluid={content.image}
      > </BackgroundImage>
      <div className="right-content">
        <span className="content-title">{content.title}</span>
        <p className="text-content">{content.content}</p>
      </div>
    </div>
  );
}

export default SkillsDescription;
