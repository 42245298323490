import React from "react"
import LeftCart from './cart/left-cart'
import RightCart from './cart/right-cart'
import './ocupation.scss'

import { graphql, StaticQuery } from "gatsby"

const Occupation = () => (
  <StaticQuery query={graphql`
  query {
    image1: file(relativePath: { eq: "poze/advantage1.jpg" }) {
      childImageSharp {
        fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image2: file(relativePath: { eq: "poze/Comp.jpg" }) {
      childImageSharp {
        fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image3: file(relativePath: { eq: "poze/network.jpg" }) {
      childImageSharp {
        fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image4: file(relativePath: { eq: "poze/help.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 860) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`}

   render ={ data => {
     const cartsList = [
       {
         id: 1,
         image: data.image1.childImageSharp.fluid,
         title: 'CONTENT',
         content: 'Collecting, structuring, validating and delivering value-added information to the Automotive industry.'
       },
       {
         id: 2,
         image: data.image2.childImageSharp.fluid,
         title: 'SOFTWARE DEVELOPMENT',
         content: 'Custom software development on behalf of clients, including software engineering, system integration and software support.'
       },
       {
         id: 3,
         image: data.image3.childImageSharp.fluid,
         title: 'DATA PLATFORMS',
         content: 'Building and operating API and cloud-based content management systems.'
       },
       {
         id: 4,
         image: data.image4.childImageSharp.fluid,
         title: 'CONSULTING SERVICES',
         content: 'Deploying experienced engineers and software developers at clients’ sites for short, medium or long term assignments.'
       }
     ];
     return (
       <div className="occupation" id="occupation">
         <div className="container">
           <div className="occupation-title">
             <h2>What we do</h2>
           </div>
           <div className="carts-block">
             {
               cartsList.map((cart, index) => {
                 if (index % 2 === 0) {
                   return <LeftCart key={cart.id} title={cart.title} imageSrc={cart.image} content={cart.content} />
                 } else {
                   return <RightCart key={cart.id} title={cart.title} imageSrc={cart.image} content={cart.content} />
                 }
               })
             }
           </div>
         </div>
       </div>
     )
   }
   }
  />
)

export default Occupation
