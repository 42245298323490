import React from "react"
import Leader from "./leader/leader"
import "./leadership-block.scss"
import { graphql, StaticQuery } from "gatsby"

const LeadershipBlock = () => (
  <StaticQuery query={graphql`
  query {
    image1: file(relativePath: { eq: "poze/Florent_Boutellier.jpg" }) {
      childImageSharp {
        fixed(quality: 70, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    image2: file(relativePath: { eq: "poze/Mariana_Gurghis.jpg" }) {
      childImageSharp {
        fixed(quality: 70, width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`}

    render={data => {
      // Set ImageData.
      const leaders = [
        {
          id: "1",
          firstName: "Florent",
          lastName: "Boutellier",
          country: "France",
          photoSrc: data.image1.childImageSharp.fixed,
          linkedinUrl: "https://www.linkedin.com/in/boutellier/"
        },
        {
          id: "2",
          firstName: "Mariana",
          lastName: "Gurghis",
          country: "Moldova",
          photoSrc: data.image2.childImageSharp.fixed,
          linkedinUrl: "https://www.linkedin.com/in/mariana-gurghis-46100194/"
        },

      ]
      return (
        <div className="leadership-container">
          <div className="container">
            <div className="leadership-block">
              <div className="title">
                <h2>Our leadership</h2>
              </div>
              <div className="leaders-info">
                {
                  leaders.map((leader) => {
                    return <Leader key={leader.id}
                      name={leader.firstName + ' ' + leader.lastName}
                      country={leader.country} image={leader.photoSrc}
                      linkedinUrl={leader.linkedinUrl}/>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
    }
  />
);

export default LeadershipBlock;
