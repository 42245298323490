import React from "react"
import './left-cart.scss'
import BackgroundImage from "gatsby-background-image"

const LeftCart = (props) => {
    const {title, imageSrc, content} = props;
    return (
      <div className="left-cart-block">
        <BackgroundImage Tag="div"
                         className="cart-image"
                         fluid={imageSrc}
        > </BackgroundImage>
          <div className="cart-text-block">
              <h4 className="title">{title}</h4>
              <p className="content">{content}</p>
          </div>
        </div>
    )
}

export default LeftCart
