import React from "react"
import './right-cart.scss'
import BackgroundImage from 'gatsby-background-image';

const RightCart = (props) => {
  const {title, imageSrc, content} = props;

  return (
      <div className="right-cart-block">
        <div className="cart-text-block">
          <h4 className="title">{title}</h4>
          <p className="content">{content}</p>
        </div>
        <BackgroundImage Tag="div"
                         className="cart-image"
                         fluid={imageSrc}
        > </BackgroundImage>
      </div>
    )
  }

export default RightCart
