import React, {Component} from 'react';
import './about-us.scss';

class AboutUs extends Component {
    render() {
        return (
            <div className="about-us-block" id="about-us-block">
                <div className="container">
                    <div className="title">
                        <h2>Who we are</h2>
                    </div>
                    <div className="about-us-text">
                        <span><strong>Amarela</strong> is a Paris based company, with a subsidiary in Chisinau, Moldova.</span>
                        <span>Our company’s mission is to provide solutions for Connected Cars and Autonomous Driving. We provide the highest level of service with experienced and committed professionals who understand how to leverage communication, relationships, and technology to provide solutions and products that exceed the expectations of our customers.</span>
                        <span>Amarela successfully provides full lifecycle software development services from architecture and UI design, development and testing to integration and maintenance and our solutions include Content, Platforms, Software Development & Consulting services. We actively contribute to creating new jobs and attract rising IT talents that shape the future of autonomous driving.</span>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default AboutUs;
