import React from 'react';
import './leader.scss';
import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import { FaLinkedin} from "react-icons/fa"

const Leader = (props) => (
  <StaticQuery query={graphql`
  query {
    logo: file(relativePath: { eq: "icons/linkedin-logo.png" }) {
      childImageSharp {
        fixed(quality: 70, width: 35,  height: 35) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      }
    }`
  }
    render={data => {
      const { name, country, image, linkedinUrl } = props;
      return (
        <div className="leader-block">
          <Img
            fixed={image}
            alt="leaderportret"
            className="leader-photo" />
          <p className="full-name">{name},<br /><span> {country}</span></p>
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
            <div className="linkedin-logo">
              <FaLinkedin className="icon" />
            </div>
          </a>
        </div >
      );
    }
    }
  />
)


export default Leader;
