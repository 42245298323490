import React from 'react';
import './banner.scss';
import BackgroundImage from 'gatsby-background-image';
import { graphql, StaticQuery } from "gatsby"

const Banner = () => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "poze/2019-mercedes-benz-b-clas-15_1600x0w.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
   render={data => {
     // Set ImageData.
     const imageData = data.desktop.childImageSharp.fluid
     return (
       <BackgroundImage Tag="div"
                        className="introduction-block"
                        fluid={imageData}
       >
         <div className="layer">
           <div className="container">
             <p>Beyond content & solutions for the automotive industry</p>
           </div>
         </div>
       </BackgroundImage>
     )
   }
   }
  />
)



export default Banner;
