import React from 'react';
import SkillsDescription from './skills-description/skills-description'
import './skills.scss'

import { graphql, StaticQuery } from "gatsby"



const Skills = () => (
  <StaticQuery query={graphql`
  query {
    image1: file(relativePath: { eq: "poze/skills.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 860) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image2: file(relativePath: { eq: "poze/googleing.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 860) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    image3: file(relativePath: { eq: "poze/image3.jpg" }) {
      childImageSharp {
        fluid(quality: 70, maxWidth: 860) {
              ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`}
    render={data => {
      const skillsContent = [
        {
          id: 1,
          title: 'We trust in data',
          image: data.image1.childImageSharp.fluid,
          content: 'Extensive experience in sourcing, processing, cleaning and delivering high ' +
            'value & real-time automotive-grade content for connected services.'
        },
        {
          id: 2,
          title: 'We believe in people',
          image: data.image2.childImageSharp.fluid,
          content: 'A team of highly skilled engineers and software developers, with years of experience' +
            ' in building, testing and debugging complex systems in Automotive and Robotics.'
        },
        {
          id: 3,
          title: 'We provide the best',
          image: data.image3.childImageSharp.fluid,
          content: 'We use only proven and reliable solutions. To ensure a high level of security and' +
            ' performance we use the latest technology and take great care of our systems and platforms used by our customers.'
        }
      ];
      return (
        <div className="skill-container" id="skills">
          <div className="container">
            <div className="skills-block">
              <div className="skills-title">
                <h2>Our skills</h2>
              </div>
              <div className="skills-description">
                {
                  skillsContent.map((data) => {
                    return <SkillsDescription key={data.id} content={data} />
                  })
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
    }
  />
)
export default Skills;
