import React from "react"

import Layout from "../components/layout"
import Banner from "../components/main/banner/banner"
import AboutUs from "../components/main/about-us/about-us"
import LeadershipBlock from "../components/main/leadership-block/leadership-block"
import Occupation from "../components/main/occupation/occupation"
import Skills from "../components/main/skills/skills"
import Join from "../components/main/join/join"
import {Helmet} from "react-helmet/es/Helmet"
import favicon from "../images/favicons/favicon.ico"


const IndexPage = () => (
  <Layout>
      <Helmet>
        <title>Amarela - Content & Solutions for Automotive Industry</title>
        <meta name="title" content="Amarela - Content & Solutions for Automotive Industry"/>
        <meta name="description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content="Amarela - Content & Solutions for Automotive Industry"/>
        <meta property="og:description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
        <meta property="og:image" content="https://static.md/9706d715358d89d8a26a3ed840e45688.png"/>
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content="Amarela - Content & Solutions for Automotive Industry"/>
        <meta property="twitter:description" content="Amarela is a leading provider of solutions and data for autonomous driving, located in Paris and Chisinau."/>
        <meta property="twitter:image" content="https://static.md/9706d715358d89d8a26a3ed840e45688.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon}/>
        <link rel="icon" type="image/png" sizes="16x16" href={favicon}/>
      </Helmet>
    <Banner/>
    <AboutUs/>
    <LeadershipBlock/>
    <Occupation/>
    <Skills/>
    <Join/>
  </Layout>
)

export default IndexPage
